



























































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import LoansTabs from "@/components/loans/LoansTabs.vue";
import { Loan } from "@/types/";
import { mapGetters } from "vuex";
import ModalContainer from "../components/ModalContainer.vue";
import { apiAxios } from "@/store/apiAxios";
import { BIconCheckCircle } from "bootstrap-vue";

export default Vue.extend({
  name: "Loans",
  components: {
    PageHeading,
    ModalContainer,
    // BIconPlus,
    LoansTabs,
    BIconCheckCircle
  },
  data:() => ({
    fetchedconsent: null as any,
  }),
  watch: {
    popupAlert: {
      handler: function () {
        if (this.popupAlert && !this.viewed) {
          this.$bvModal.show("modal-alert-popup");
        }
      },
      immediate: true,
    },
    '$route.query.consent':{
        handler: function(val) {
          if (val === 'true') {
            this.$nextTick(() => {
              this.$bvModal.show("modal-success");
            })
          }
        },
        immediate: true,
    },
    userConsent: {
      handler: function () {
        this.$store.dispatch("bankstatement/fetchUserConsent", this.userAuth.id).then((r) => {
          this.fetchedconsent = r;
          this.userConsent = this.$store.getters["bankstatement/getUserConsent"];
          if (this.userConsent == true) {
            this.$bvModal.show("modal-nordigen-consent");
          }
        });
      },
      deep: true,
    },
    
  },
  mounted() {
    if (this.$route.query.consent === 'success') {
      this.$bvModal.show("modal-success");
    }
    if (this.popupAlert && !this.viewed) {
      this.$bvModal.show("modal-alert-popup");
    }
    this.$store.dispatch("loans/fetchLoans").then(() => {
      if (this.selectedLoan === null) {
        this.selectedLoan = this.getMostRecentLoan();
      }
    });
    this.$store.dispatch("user/popupAlert");
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.$store.dispatch("payments/fetchSingleLoanPayments");
        this.$store.dispatch("bankstatement/fetchUserConsent", this.userAuth.id).then((r) => {
          this.fetchedconsent = r;
        });
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  computed: {
    ...mapGetters({
      activeLoans: "loans/getActiveLoans",
      loans: "loans/getLoans",
      popupAlert: "user/getPopupAlert",
      titleBoxCustom: "user/getTitleBoxCustom",
      descriptionBoxCustom: "user/getDescriptionBoxCustom",
      userAuth: "auth/authUser",
    }),
    selectedLoan: {
      get(): number | null {
        return this.$store.getters["loans/selectedLoanId"];
      },
      set(value: number) {
        this.$store.commit("loans/setSelectedLoanId", value);
      },
    },
    loan(): Loan {
      return this.loans.filter(
        (loan: Loan) => loan.ac_deal_id == this.selectedLoan
      )[0];
    },
    viewed: {
      get(): boolean {
        return this.$store.getters["user/getViewedPopup"];
      },
      set(value: boolean) {
        this.$store.commit("user/setViewedPopup", value);
      },
    },
    userConsent: {
      get(): boolean {
        return this.$store.getters["bankstatement/getUserConsent"];
      },
      set(value: boolean) {
        this.$store.commit("bankstatement/setUserConsent", value);
      }
    }
  },
  methods: {
    getMostRecentLoan() {
      const currentTime = Date.now();
      let diff = this.loans.map((l: Loan) => {
        return Math.abs(currentTime - Number(l.loan_start_date));
      });

      let i = diff.indexOf(Math.min(...diff));

      if (i == -1) {
        i = this.loans.length - 1;
      }

      return this.loans[i].ac_deal_id;
    },
    closePopupAlert() {
      this.viewed = true;
      this.$bvModal.hide("modal-alert-popup");
    },
    reject() {
      apiAxios.post(`/api/bankstatement/${this.userAuth.id}?rejected=1`);
      this.$bvModal.hide("modal-nordigen-consent");
    },
    redirectToconsentLink() {
      window.location.href = this.fetchedconsent.consent_link;
    },
    closePopupSuccess() {
      this.$router.replace('/');
      this.$bvModal.hide("modal-success");
    },
  },
});
