








































































































import Vue from "vue";
import i18n from "@/i18n";
import { Loan, TableField } from "@/types";
import { mapGetters } from "vuex";

// All current loan statuses should be:
//  - 'Behind on payments'
//  - 'Payments are on schedule'
//  - 'Payment is ahead of schedule'
// on basis of the (endpoint portfolio) / (days in arrears), which shows the payment progress of loans.

// All completed loans should have the status:
//  - 'Loan is repaid in full' (when fully repaid)
//  - 'Application is declined'

export default Vue.extend({
  name: "LoansTable",
  props: {
    loanStatus: {
      type: String,
      required: false,
      default: "Current",
    },
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoansByStatus",
    }),
    loansLoading(): boolean {
      return !this.$store.state.loans.fetched;
    },
    hasLoansInStatus(): boolean {
      return this.loans(this.loanStatus).length !== 0;
    },
    fields(): TableField[] {
      // Accessing this.$i18n.locale in this computed prop tells Vue that
      // this computed prop should update whenever this.$i18n.locale
      // changes it value, even though we don't use the value.
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return [];
      }
      return [
        {
          key: "application_id",
          label: this.$t("loansPage.loansTable.application-id"),
          sortable: true,
          thClass: "b-table-sort-icon-left d-none d-md-table-cell",
          tdClass: "d-none d-md-table-cell",
        },
        {
          key: "loan_start_date",
          label: i18n.t("loansPage.loansTable.start-date"),
          sortable: true,
          thClass: "b-table-sort-icon-left d-none d-md-table-cell",
          tdClass: "d-none d-md-table-cell",
        },
        {
          key: "loan_end_date",
          label: this.$t("loansPage.loansTable.end-date"),
          sortable: true,
          thClass: "b-table-sort-icon-left d-none d-md-table-cell",
          tdClass: "d-none d-md-table-cell",
        },
        {
          key: "totalamount",
          label: this.$t("loansPage.loansTable.loan-amount"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "payment_status",
          label: this.$t("loansPage.loansTable.status"),
        },
        {
          key: "more",
          label: this.$t("loansPage.loansTable.more"),
        },
      ];
    },
  },
  methods: {
    openLoanDetails(record: Loan) {
      this.$router.push("/loans/" + record.ac_deal_id);
    },
  },
});
