



















import Vue from "vue";
import LoansTable from "@/components/loans/LoansTable.vue";

export default Vue.extend({
  name: "LoansTabs",
  components: {
    LoansTable,
  },
  computed: {
    activeTabIndex() {
      return parseInt(this.$route.params.tabIndex) ?? 0;
    },
  },
});
